@import 'theme/_s1v10colors.scss';

.attachment-list {
  border-top: 1px solid $divider;
  min-height: 65px;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: $grey-50;
    cursor: pointer;
  }

  &:nth-child(1){
    border: none;
  }
}

.attachment-list__icon {
  padding: 0 10px;
}

.attachment-list__filename {
  width: 200px;
  padding-right: 10px;
}

.attachment-list__description {
  width: 950px;
}

.attachment-list__date {
  padding-right: 10px;
  color: $text-primary;
}
