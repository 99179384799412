@import 'theme/_s1v10colors.scss';

.eula-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
  
.eula-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  border: 1px solid $grey-400;
  background-color: $background-default;
}