@import 'theme/_s1v10colors.scss';

.difference-field-form__wrapper {
  display: flex;
  align-items: center;

  .label__container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex: 1 0 100px;
    align-items: center;
    padding-right: 15px;

   .label__container-left {
      display: flex;
      color: $text-primary;
      align-items: center;

      .label__left-box-name {
        width: 20px;
        padding-right: 10px;
      }

      .select-field__label {
        font-size: 13px;
        padding: 0 5px;
      }
    }

    .label__box-name {
      font-size: 11pt;
      font-weight: 500;
    }
  }

  .difference-field__display {
    display: flex;
    justify-content: flex-end;
    width: 42.8%;

    .previously-value__container {
      height: 50px;
      margin: -5px 0;
      width: 33.33%;

      input {
        text-transform: uppercase;
        text-align: right;
        text-overflow: ellipsis;
      }
    }
  }

  .actual-value__container {
    height: 50px;
    width: 66.66%;
    margin: -5px 0;

    .select-field-form__mui-select-field:before {
      border-style: none;
    }

    .select-field-form__mui-select-field {
      padding-left: 10px;
      height: 100%;
      border-left: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0;

      > fieldset {
        border: none;
      }
    }
  }
}