@import 'theme/_s1v10colors.scss';

.with-columns-by-product__status {
  &--error {
    display: flex;
    align-items: center;

    > p {
      margin-left: 10px;
    }
  }

  &--no-error {
    fill: $success-main
  }

}