@import 'theme/_s1v10colors.scss';

.formComponents-information__title {
  font-size: 15px;
  font-weight: 500;
}

.recipient__popover div, .payer__popover div {
  overflow: visible !important;
}

.payer__popover > div {
  display: flex;
}

.recipient__popover, .payer__popover {
  .react-autosuggest__container {
    padding: 10px;
  }

  .react-autosuggest__input {
    background: transparent;
    width: 360px;
    padding: 0;
    border: none;
    color: gray;
    border-bottom: 1px solid $divider;
  }

  .react-autosuggest__suggestions-container--open {
    width: 380px;
    border: none;
    margin: 10px 0 0 -10px;
    box-shadow: 3px 8px 10px $divider;
  }

  .react-autosuggest__suggestions-list {
    max-height: 50vh;
    overflow-y: auto;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: $background-default;
  }
}

.formComponents-information__container-right {
  padding-top: 7px;
}

.formComponents-information__verification-label {
  padding-bottom: 10px;
}

.payer-recipient__search-spinner {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px !important;
  height: 30px !important;
  margin: 10px;
  color: $grey-200;
}