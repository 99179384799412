@import 'theme/_s1v10colors.scss';

body {
  background-color: $background-default;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

a {
  text-decoration: none;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 100px white inset;
}

input::-ms-clear { display: none; }
input::-ms-reveal { display: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.AppLayout {
  display: flex;
  align-content: stretch;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-x: auto;
  overflow-y: hidden;
}

.AppLayout__MainContent {
  flex-grow: 1;
  overflow: hidden;
}

.App__raisedButton--color span {
  color: $text-contrast !important;
}

// Waiting to hear from Andy Grauch about moving this to S1-UI repo

@media (max-width: 1050px) {
  .slidingPanel__childContainer {
    min-width: auto !important;
  }
  .slidingPanel__childContainer {
    width: auto !important;
  }
}

.slidingPanel__childContainer > div:last-child {
  display: flex;
  flex-direction: column;
}

.slidingPanel__childContainer > div:last-child > div:last-child {
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
}

.sliding-panel__header {
  justify-content: center !important;
}

:invalid {
  box-shadow: none;
}

.action-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 8px;
}
