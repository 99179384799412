@import 'theme/_s1v10colors.scss';

.form-comment__wrapper {
  background-color: $background-default;
  min-height: 85px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
}

.form-comment__comment {
  width: 100%;
  padding: 10px;
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.form-comment__non-first-comment {
  border-top: 1px solid;
}

.form-comment__left-content {
  word-break: break-all;
  display: flex;
  align-items: center;
}

.form-comment__right-content {
  min-width: 100px;
  text-align: right;
}

.form-comment__left-content_white-space {
  white-space: pre-wrap;
}
