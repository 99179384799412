@import 'theme/_s1v10colors.scss';

// TODO: temporal fix, check comment in SlidingFormEditor.js
.form-editor__slider-root {
  .MuiPaper-root {
    overflow: hidden;

    .sovosSlidingPanelContainer {
      > div {
        height: calc(100vh - 49px);
        overflow-y: auto;
      }
    }
  }
}

.form-editor__slider-content {
  height: calc(100vh - 49px);
  padding: 0;
  overflow-y: auto;
}

.form-editor__slider-paper {
  overflow: hidden;
}

.form-editor__header_title {
  display: flex;
  align-items: center;

  .header_title {
    margin-right: 30px;
    max-width: 54vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.form-editor__header_pt-label {
  background-color: $grey-300;
  margin-right: 10px;
}

.form-editor__container {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  > .form-editor__select-field {
    width: 25%;
  }
}

.sovosSlidingPanel > div:nth-of-type(2) {
  overflow-y: hidden;
}

.sovosSlidingPanel__container {
  position: relative;
  padding-bottom: 40px;
  margin: -24px
}

.form-options__tooltip_child {
  pointer-events: auto;
}