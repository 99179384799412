@import 'theme/_s1v10colors.scss';

.field-header {
  &__helper-container {
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-left: 1px solid $divider;
  }

  &__helper-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
  }

  &__helper-disabled {
    background-color: $grey-300
  }
}