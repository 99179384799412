@import 'theme/_s1v10colors.scss';

#launcher {
  z-index: -1 !important;
}

div[class^="NavigationFooter-userMenuHeader"] {
  span {
    font-size: 14px;
    line-height: 20px;
    padding-left: 15px;
    font-weight: 500;
    word-break: break-word;
  }
}

div[class^="SlidingPanel-paper"] {
  overflow-y: visible;
}

.sovos-navigation-context-switcher > button span {
  font-weight: 500;
  opacity: 1;
  text-transform: capitalize;
}

.sovosToolbar {
  border-bottom: 1px solid $divider;
}

.sovosToolbar div:nth-child(2) {
  flex: 0 0 auto;
}

.sovosNavigationFooter > button {
  width: auto;
  height: auto;
}

.sovosSelect input {
  box-sizing: border-box;
}

@media (max-width: 959px) {
  .sovosPageTitleBar > div {
    justify-content: normal;

    > div:nth-child(3) {
      position: absolute;
      right: 20px;
      top: 5px;
    }
  }
}

b, strong, h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #A0A0A0;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #585858;
}

.tir-sovosSnackbar_automatic-logout__content {
  max-width: none !important;
}

.sovosNavigation-disable {
  display: none;
}
