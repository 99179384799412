@import 'theme/_s1v10colors.scss';

.required-message {
  background-color: $grey-50;
  padding-left: 15px;
  min-height: 30px !important;
  height: 30px !important;
  font-size: 8pt;

  &__asterisk {
    color: $error-main;
  }

  &__text {
    color: $text-primary;
  }
}