.history-tab__loader {
  height: 100px;
  display: flex;
  justify-content: center;
}

.history-tab__main-div {
  min-height: 60px;
  padding: 20px;
  max-height: 600px;
  overflow-y: auto;
}