.other-values__container {
  padding: 0;
  display: block !important;

  .other-values__previous-current-text-field-form-container {
    width: 100%;
  }
  
  .other-values__amount-different-fields {
    width: auto !important;
  }
}
