.records-card__cards-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 20px 0;
  width: 780px;
}

.records-card__cards-container > div {
  margin: 0 15px 5px 0;
}

.records-card__cards-container > :first-child {
  margin-left: 0;
}

.records-card__cards-container > :last-child {
  margin-right: 0;
}
