@import 'theme/_s1v10colors.scss';

.form-in-tabs {
  &__container {
    position: relative;
    padding-bottom: 40px;
    margin: -24px;
  }

  &__icons {
    position: absolute;
    z-index: 1;
    top: 6px;
  }

  &__icon {
    fill: $primary-main;
  }

  &__menu-item {
    font-size: 14px;
  }

  &__tab {
    background-color: $text-contrast;
    width: 140px;
  }

  &__tab-inactive {
    color: $text-primary;
  }

  &__tab-active {
    color: $primary-main;
  }

  &__tab-label {
    color: $text-primary;
  }
}