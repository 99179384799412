@import 'theme/_s1v10colors.scss';

.radiosListGroup {
  &__label:not(:first-child) {
    margin-top: 5px
  }
  
  &__radioRoot {
    padding: 0 !important;
    margin: 0 6px 0 12px !important;
  }

  &__radioChecked {
    color: $primary-main !important
  }
}