@import 'theme/_s1v10colors.scss';

.data-reset__code-security {
  font-size: 18px;

  span:nth-child(1) {
    margin-right: 10px;
  }

  span:nth-child(2) {
    font-weight: 500;
  }
}

.checkbox__red {
  svg {
    fill: $error-main !important;
  }
}

.checkbox__inactive {
  svg {
    fill: rgba(33, 33, 33, 0.3) !important;
  }
}

.reset-dialog {
  height: 415px !important;
}

.reset__indicator {
  height: 200px;
}

.checkbox__label {
  color: $text-primary;
  margin: 0;
  justify-content: space-between;
}