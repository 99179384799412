@import 'theme/_s1v10colors.scss';

.tabbed-output-overview {
  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
  }

  &__table {
    margin-bottom: 8px;
  }

  &__table-container {
    height: 100%;
  }

  &__button {
    margin: 8px;
    align-self: flex-end;
  }
}