@import 'theme/_s1v10colors.scss';

.identity-verification {
  &__container {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 0 0 10px;
    align-items: flex-start !important;
  }

  &__verification-label {
    padding: 8px 0 !important
  }

  &__recipient-ungrouped {
    background-color: $warning-light;
    margin-right: 15px;
    padding: 8px;
    margin-bottom: 14px;
  }
}