.form-history__block {
  display: inline-block;
  vertical-align: top;
}

.form-history__text {
  width: 80%;
  word-break: keep-all;
}

.form-history__container {
  border-top: 1px solid #EEE;
  display: flex;
}

.form-history__container:nth-child(1) {
  border: none;
}

.form-history__history-block {
  padding-bottom: 10px;
}

.form-history__text-value {
  margin: 5px 20px;
  padding: 0;
}

.form-history__marked-as-correction {
  font-weight: 500;
}

.form-history__text-value_identity {
  @extend .form-history__text-value;
  margin: 0 20px;
}

.form-history__text-value_statest {
  @extend .form-history__text-value;
  margin-left: 95px;

  p {
    margin: 0;
  }
}

.form-history__timestamp {
  font-size: small;
  margin-top: 18px;
  width: 20%;
  text-align: right;
}
