@import 'theme/_s1v10colors.scss';

.form-validations__container {
  display: block;
  background-color: $error-main;
  color: $text-contrast;
  padding: 15px;
  margin-bottom: 7px;

  h5 {
    font-weight: 500;
    padding: 0;
    margin: 0;

    span {
      float: right;
    }
  }

  h5:hover {
    cursor: pointer;
  }

  .form-validations__validation-list {
    height: auto;
    overflow: hidden;
    transition: max-height .5s;

    .form-validations__validation {
      span {
        font-weight: 500;
      }

      span::before {
        content: '-';
        margin-right: 2px;
      }
    }
  }

  .form-validations__validation-list__collapsed {
    max-height: 0;
  }

  .form-validations__validation-list__expanded {
    max-height: 500px;
  }
}