@import 'theme/_s1v10colors.scss';

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 254px;
  height: 30px;
  padding: 10px 20px;
  margin: 6px 0;
  font-weight: 300;
  font-size: 16px;
  border: none;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 320px;
  border: 1px solid $divider;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  background-color: $background-default;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.typeahead__suggestions-disabled li {
  pointer-events: none;
}