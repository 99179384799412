@import 'theme/_s1v10colors.scss';

.attachments-tab__loading {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachments-tab__attachment-div {
  padding-top: 20px;
  background-color: $background-default;
}

.attachments-tab__empty {
  border-color: $grey-50;
  color: $grey-900;
  min-height: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
}
