@import 'theme/_s1v10colors.scss';

.semi-weekly-amounts {
  &__container {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: center;
    margin-bottom: -1px;
  }

  &__column-1 {
    flex-grow: 1;
    border-right: 1px solid $divider;
  }

  &__column-2 {
    flex-grow: 1;
    border-left: 1px solid $divider;
    margin-left: -1px;
  }
}