@import 'theme/_s1v10colors.scss';

.radio-button_size {
  font-size: 10px;
}

.radio-button__container > div {
  display: flex;
}

.radio-button__container-30 {
  width: 30%;
}

.radio-button__group {
  display: flex;
  flex-direction: row !important;
}

.radio-button__group-vertical {
  min-height: 80px;
  display: flex;
  border-left: 1px solid $divider;
  flex-direction: column !important;
  padding: 8px 0 8px 8px;
}

.radio-button__group .radio-button_size--disabled svg {
  fill: currentColor;
  pointer-events: auto;
  cursor: not-allowed;
}