@import 'theme/_s1v10colors.scss';

.difference-values-header {
  background-color: $grey-50;
  font-size: 12px;

  > span:nth-child(1) {
    margin-left: 58%;
  }

  > span:nth-child(2) {
    margin-left: 4.5%;
  }

  > span:nth-child(3) {
    margin-left: 7%;
  }
}
