.pt-order-status {
  display: flex;
  flex-direction: column;

  .card-title {
    font-weight: 400;
    font-size: 18px;
  }

  .card-content {
    margin: 0 5px;
  }

  .view-all-button {
    margin: 15px 0;
    width: 220px;
    align-self: flex-end;
  }

  &__showMoreButton {
    margin: 8px;
    align-self: flex-end;
  }

  &__table {
    margin-bottom: 8px;
  }
}