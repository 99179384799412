@import 'theme/_s1v10colors.scss';

.multi-rows__container {
  padding: 0;
  display: block !important;

  .multi-rows__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $divider;
    border-right: 1px solid $divider;
    min-height: 50px;

    .label__box-name {
      width: 30px;
      padding-right: 10px;
    }
  }

  .multi-rows__remove-row {
    margin: 0 10px;
  }

  .multi-rows__button-container {
    padding-left: 90px;
    border-right: 1px solid $divider;

    .card-flat-button {
      margin: 8px 0;
    }
  }
}
