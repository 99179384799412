@import 'theme/_s1v10colors.scss';

.form-1042-schedule-qs {
  &__container {
    margin-top: 20px;
    flex-direction: column;
    align-items: stretch !important;
      > div {
        border-bottom: 1px solid $divider;
      }
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    padding-right: 30px;
    padding-left: 15px;

    &__text {
      display: flex;
      align-items: center;

      &__main {
        font-weight: 600;
      }
    }
  }
}