.section-header {
  height: 60px;
  padding-left: 15px;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;

  &__title {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }

  &__subtitle {
    margin: 0;
    font-size: 13px;
    opacity: 0.7;
  }
}