@import 'theme/_s1v10colors.scss';

.eula-dialog {
  &__title {
    h2 {
      font-weight: bold;
    }
  }

  &__content {
    border-style: solid;
    border-color: #ccc;
    border-width: 1px;
    height: 400px;
    margin: 0px 5px 2px;
  }

  &__footer {
    > span {
      float: left;
      margin-left: 16px;
      padding-right: 16px;
      font-size: 14px;
    }
  }
}


