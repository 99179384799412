@import 'theme/_s1v10colors.scss';

.tin-suggestions-box {
  width: 380px;
  position: absolute;
  left: 146px;
  top: 50px;
  background: $background-default;
  position: absolute;
  border-radius: 3px;
  z-index: 1;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12);

  .tin-suggestion-box__message {
    font-size: 12px;
    margin: 5px 0 0;
    padding: 0 10px;
    color: $error-dark;
  }

  > div {
    p {
      margin: 0;
      padding: 3px 10px;
      font-size: 12px;

      span {
        font-weight: bold;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $background-default;
    }
  }
}