@import 'theme/_s1v10colors.scss';

.sovos-dynamic-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  overflow-x: hidden;
}

.sovos-dynamic-form h4 {
  padding: 10px 0 10px 10px;
  margin: 0;
  border-bottom: 1px solid $grey-50;
  font-size: 13px;
  font-weight: bold;
}

.xs-3 h4 {
  border-bottom: none;
}

.sovos-dynamic-form__rows {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.sovos-dynamic-form__rows > .sovos-dynamic-form__dynamic-form-component {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
}

.sovos-dynamic-form__dynamic-form-component > .sovos-dynamic-form__rows:last-child {
  margin-bottom: 0;
}

.sovos-dynamic-form__panels {
  display: inline-block;
  border-radius: 3px;
  border-left: 1px solid $grey-300;
}

.sovos-dynamic-form__dynamic-form-component > .sovos-dynamic-form__panels:first-child {
  border-left: none;
  padding-left: 0;
}

.sovos-dynamic-form__panels > .sovos-dynamic-form__dynamic-form-component {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.sovos-dynamic-form__panels.sovos-dynamic-form__tax-form:has( + .sovos-dynamic-form__panels.sovos-dynamic-form__tax-form) {
  margin: 0 20px;
  .sovos-dynamic-form__fields {
    border-bottom: none !important;
  }
}

.sovos-dynamic-form__panels.sovos-dynamic-form__tax-form + .sovos-dynamic-form__panels.sovos-dynamic-form__tax-form {
  border: 1px solid $divider;
  border-bottom: 0;
  border-radius: 0;

  .sovos-dynamic-form__fields {
    border-bottom: 1px solid $divider;
  }
}

.xs-3 .sovos-dynamic-form__fields {
  border-bottom: none;
}

.sovos-dynamic-form__fields {
  color: $text-primary;
  font-weight: 400;
  border-bottom: 1px solid $grey-300;

  > div {
    min-height: 50px;
    display: flex;
    align-items: center;
  }

  input:disabled {
    color: $text-disabled !important;
    background-color: $action-disabledBackground !important;
    pointer-events: auto;
    cursor: not-allowed;
  }

  textarea:disabled {
    color: $text-disabled !important;
    background-color: $action-disabledBackground !important;
    pointer-events: auto;
    cursor: not-allowed;
  }

  select:disabled {
    color: $text-disabled !important;
    background-color: $action-disabledBackground !important;
    cursor: not-allowed;
  }
}

.label__container {
  padding-left: 15px;
}

.tirTexfield__wrapper {
  padding-left: 15px;
}

.select-field__wrapper {
  padding-left: 15px;
}

.formComponents-information__container {
  padding-left: 10px;
}

.checkbox__wrapper {
  padding-left: 15px;
  border-right: 1px solid $grey-300
}

.xs-12 {
  width: 100%;
}

.xs-11 {
  width: 91.6%;
}

.xs-10 {
  width: 83.3%;
}

.xs-9 {
  width: 75%;
}

.xs-8 {
  width: 66.6%;
}

.xs-7 {
  width: 58.3%;
}

.xs-6 {
  width: 50%;
}

.xs-5 {
  width: 41.6%;
}

.xs-4 {
  width: 33.3%;
}

.xs-3 {
  width: 25%;
}

.xs-2 {
  width: 16.6%;
}

.xs-1 {
  width: 8.3%;
}

.xs-offset-11 {
  margin-left: 91.6%;
}

.xs-offset-10 {
  margin-left: 83.3%;
}

.xs-offset-9 {
  margin-left: 75%;
}

.xs-offset-8 {
  margin-left: 66.6%;
}

.xs-offset-7 {
  margin-left: 58.3%;
}

.xs-offset-6 {
  margin-left: 50%;
}

.xs-offset-5 {
  margin-left: 41.6%;
}

.xs-offset-4 {
  margin-left: 33.3%;
}

.xs-offset-3 {
  margin-left: 25%;
}

.xs-offset-2 {
  margin-left: 16.6%;
}

.xs-offset-1 {
  margin-left: 8.3%;
}

.xs-offset-0 {
  margin-left: 0;
}

.status-field {
  &_icon-errors {
    color: $error-main;
  }

  &_icon-accepted {
    color: $success-main;
  }
}