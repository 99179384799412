.logout-sso {
  min-height: 100vh;
  display: flex;
  align-items: stretch;

  &__left-side {
    flex: 1;
    padding: 64px 40px;
    img {
      margin-top: 150px;
      margin-bottom: 20px;
    }
    p {
      margin: 0px 0px 24px;
      font-weight: 400;
      letter-spacing: -0.1px;
      line-height: 1.42857;
      font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: rgb(84, 94, 117);
      font-size: 18.4px;
    }
  }

  &__right-side {
    background-image: url('./banner-bg-greyscale.jpg');
    width: 400px;
  }

  &__right-side-content {
    background-color: rgb(44, 68, 146);
    height: 100%;
    mix-blend-mode: hard-light;
    color: white;
    text-align: center;
    padding: 40px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    text-shadow: white 0px 0px 1px;

    h3 {
      margin: 0px;
      font-weight: 700;
      font-size: 1.75rem;
      letter-spacing: -0.2px;
      line-height: 1.42857;
      font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }

  &__right-side-text {
    margin: 24px 0px 0px;
    line-height: 32px;
  }

  &__login-button {
    width: 200px;
  }
}