.container-loader {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loader-circular {
    width: 100%;
  }

  .loader-text {
    margin-top: 10px;
  }
}