@import 'theme/_s1v10colors.scss';

#full-page-loader {
  position: relative;
  z-index: 999;

  & > .full-page-loader__loading-animation {
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(2px);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > .full-page-loader__typography {
      color: $text-contrast;
      margin: 20px 0
    }
  }
}