@import 'theme/_s1v10colors.scss';

.validation-grid-textField {
  &__input-base {
    position: unset !important;
    background: transparent;
    border-radius: 0;
  }
  &__input-underline::before {
    visibility: hidden;
  }
  &__input-underline:hover::before {
    visibility: visible;
  }
  &__input-base.MuiInput-underline::before {
    display: none;
  }
  &__input-base.Mui-focused {
    background-color: transparent;
  }
  &__input-base:hover {
    background-color: transparent;
  }
  &__input-base:hover::before {
    border-bottom: none !important;
  }
}

.tirTexfield__wrapper {
  display: flex;

  .tirTexfield__label {
    width: 125px;
    min-width: 125px;
    font-size: 13px;
    font-weight: 400;
    padding-right: 5px;
    user-select: none;
    display: flex;
    align-items: center;
    color: $text-primary;
  }

  .tirTexfield__input {
    flex-grow: 1;
    font-weight: 400 !important;
    font-size: 8pt !important;
    width: 100% !important;
    height: 50px !important;
    border-left: 1px solid $divider;
    border-right: 1px solid $divider;

    input {
      text-transform: uppercase;
      height: 50px!important;
      background-color: transparent !important;
      opacity: .9 !important;
      font-size: 13px !important;
      padding: 0 0 0 15px !important;
    }

    div {
      hr {
        bottom: 7px !important;
      }
    }

    .Mui-error::after {
      transform: scaleX(1);
    }
  }
}

.tirTextfield__helpText {
  white-space: pre-wrap;
  max-width: 250px;
  font-size: 12px;
  padding: 4px 10px;
}

.tirTextfield__helpIcon {
  cursor: pointer;
  color: $text-secondary;
  font-size: 20px;
  margin-left: 5px;
}

.tirTexfield__label-error {
  color: $error-main !important;
  font-weight: 500 !important;
}

.tir-texfield__error {
  background-color: $error-main !important;
  opacity: 1 !important;
}

.tir-texfield__error.type-dark.place-right:after {
  border-right-color: $error-main !important;
}

.tir-texfield__error.type-dark.place-left:after {
  border-left-color: $error-main !important;
}

.tir-texfield__error.type-dark.place-bottom:after {
  border-bottom-color: $error-main !important;
}

.tir-texfield__error.type-dark.place-top:after {
  border-top-color: $error-main !important;
}

.tir-texfield__error.place-right::after {
  opacity: 1 !important;
}

.tirTexfield__textarea {
  flex-grow: 1;
  font-weight: 400 !important;
  font-size: 8pt !important;
  width: 100% !important;

  div:first-child {
    height: 100% !important;
  }

  textarea {
    text-transform: uppercase;
    font-size: 13px;
  }
}

.tir-texfield-placeholder__wrapper {
  .tirTexfield__input {
    flex-grow: 1;
    width: 100% !important;

    > div {
      font-size: 13px;
      background: transparent;
    }

    > div::after {
      border-bottom: 2px solid $divider;
    }

    input {
      font-size: 13px;
    }

    input:disabled {
      background-color: transparent !important;
    }
  }

  .tirTexfield__input:focus-within {
    > label {
      color: $text-primary
    }
  }

  .tirTexfield__error {
    flex-grow: 1;
    width: 100% !important;

    > div {
      font-size: 13px;
    }
  }

  .tirTexfield__textarea textarea:disabled {
    background-color: transparent !important;
  }
}

.tirTextfieldForm__wrapper {
  display: flex;
  align-items: center;

  .label__container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex: 1 0 100px;
    align-items: center;
    padding-right: 15px;

    label {
      display: block
    }

    .label__container-left {
      display: flex;
      color: $text-primary;
      align-items: center;
    }

    .label__left-box-name {
      width: 30px;
      padding-right: 10px;
    }

    .tirTexfield__label {
      display: flex;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      padding: 0 5px;
      user-select: none;
    }

    .tirTexfield__label-box {
      font-size: 11pt;
      font-weight: 500;
    }
  }

  .tirTextField__container-30 {
    width: 30%;
  }

  .inputLengthDefault {
    flex-basis: 150px !important;
  }

  .inputLengthDate {
    flex-basis: 110px !important;

    input {
      width: 113px;
    }
  }

  .inputLengthShares {
    flex-basis: 120px !important;
  }

  .inputLength5 {
    flex-basis: 80px !important;
  }

  .inputLength20 {
    flex-basis: 230px !important;
  }

  .inputLength40 {
    flex-basis: 440px !important;
  }

  .inputLength100 {
    flex-basis: 1040px !important;
  }

  .textField__container {
    min-height: 50px !important;

    &-longText {
      height: 150px !important;
    }

    .tirTextfieldForm__input {
      width: 100%;
      font-weight: 400;
      font-size: 13px;

      > div:first-child {
        background: transparent;
        border-radius: 0;
        padding: 0;
      }

      input {
        text-transform: uppercase;
        text-align: right;
        width: 100%;

        &:hover:enabled {
          box-shadow: inset 0 0 3px 0.5px $text-primary;
        }

        &:focus:enabled {
          box-shadow: inset 0 0 7px 0.5px $text-primary;
          transition: box-shadow 0.4s;
        }
      }

      textarea {
        text-transform: uppercase;
        width: 100%;
        padding: 10px;

        &:hover:enabled {
          box-shadow: inset 0 0 3px 0.5px $text-primary;
        }

        &:focus:enabled {
          box-shadow: inset 0 0 7px 0.5px $text-primary;
          transition: box-shadow 0.4s;
        }
      }
    }
  }

  @media (max-width: 744px) {
    #A07, #A03, #A15{
      margin-top: -12px;
      height: 71px !important;
    }
  }
}

.tirTextfieldForm-longText {
  height: 150px !important;
}


div[label=Indicators] {
  .sovos-dynamic-form__rows {
    .sovos-dynamic-form__dynamic-form-component {
      .sovos-dynamic-form__fields {
        margin: 0 15px;
      }
    }
  }
}

.sovos-dynamic-form__tax-form[label=Indicators],
.sovos-dynamic-form__tax-form[label="Output options"],
.sovos-dynamic-form__tax-form[label="Internal information"] {
  margin-left: -10px !important;
  .sovos-dynamic-form__rows {
    .sovos-dynamic-form__dynamic-form-component {
      .sovos-dynamic-form__fields {
        margin: 0 0 0 15px;
        div .checkbox__wrapper {
          padding: 0;
        }
      }
    }
  }
}

.amountDifference__wrapper > .amount-field__display {
  display: flex;
  justify-content: flex-end;
  width: 42.8%;
}
