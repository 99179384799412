@import 'theme/_s1v10colors.scss';

.select-field-form__wrapper {
  height: 50px;

  > div::after {
    border-bottom: 2px solid $divider;
  }
}

.select-field__wrapper, .select-field-form__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label__container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex: 1 0 100px;
    align-items: center;
    padding-right: 15px;

    label {
      display: block;
    }

    .label__container-left {
      display: flex;
      align-items: center;
      color: $text-primary;
    }

    .label__left-box-name {
      width: 30px;
      padding-right: 10px;
    }

    .select-field__label {
      font-weight: 400;
      line-height: 15px;
      padding: 0 5px;
      user-select: none;
    }

    .select-field__label-box {
      font-size: 11pt;
      font-weight: 500;
    }
  }

  .select-field-form__disabled {
    background-color: $grey-50 !important;
    border-radius: 0;

    svg {
      display: none;
    }
  }

  .select-field-form__mui-select-field:before {
    border-style: none !important;
  }

  .select-field-form__mui-select-field {
    max-width: 700px !important;
    padding-left: 10px;
    background: transparent;
    border-radius: 0;

    > fieldset {
      border: none;
    }

    > div > select {
      padding: 0 32px 0 10px;
      width: calc(100% - 42px);
    }

    > div > svg {
      transform: scale(1.2);
    }
  }

  @media (max-width: 1550px) {
    .select-field-form__mui-select-field {
      max-width: 450px !important;
    }
  }

  @media (max-width: 1200px) {
    .select-field-form__mui-select-field {
      max-width: 340px !important;
    }
  }
}

.select-field__label {
  font-size: 13px;
  line-height: 25px;
  user-select: none;
  padding-right: 5px;
  color: $text-primary;
}

.select-field__input {
  font-size: 13px !important;
  width: 100% !important;
  border-left: 1px solid $divider !important;
  border-right: 1px solid $divider !important;
  border-radius: 0;
  background: transparent;

  &.Mui-focused {
    background-color: transparent;
  }

  &:hover {
    background-color: transparent;
  }
}

.select-field__wrapper svg {
  font-size: 25px;
  position: absolute;
  color: $text-primary;
  opacity: .9;
  margin-right: 15px;
}

.select-field__input::before {
  display: none;
}

.select-field__input-root {
  height: 100%;
  display: flex;
  align-items: center;
}

.select-field__select {
  height: 100% !important;
  width: 100% !important;
  padding: 0px 0px 0px 15px !important;
}
