@import 'theme/_s1v10colors.scss';

.wrapper-mui-textfield {
  &__root {
    border-left: 1px solid $divider;

    > div:first-child {
      background: transparent;
      border-radius: 0;
    }
  }
  &__input {
    height: 50px;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
