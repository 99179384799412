@import 'theme/_s1v10colors.scss';

.auto-check-box-field__wrapper {
  width: 100%;
  margin-left: 15px;
  border-right: 1px solid $divider;

  &__label {
    color: $text-primary !important;
    font-size: 13px !important;
  }

  &__checkbox {
    font-size: 13px;
    color: $grey-600;
    padding: 0 !important;
    margin-right: 30px !important;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
    padding-top: 1px;
  }

  label {
    line-height: 23px !important;
    user-select: none;
    margin-left: 0px !important;
    display: flex;
    justify-content: space-between;
    height: 50px;
  }
}