.recipient-information__container {
  margin: 10px 0 20px 0;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start !important;

  .formComponents-information__container-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .formComponents-information__title {
    margin-right: 5px;
  }

  p {
    font-weight: 300;
    word-break: break-all;
    margin: 0;
  }

  label {
    font-weight: 500;
  }
}
