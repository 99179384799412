.hide-arrow.place-bottom::after,
.hide-arrow.place-top::after,
.hide-arrow.place-left::after,
.hide-arrow.place-right::after {
  display: none !important;
}

button {
  .__react_component_tooltip {
    top: 40px !important;
  }
}
