@import 'theme/_s1v10colors.scss';

.comments-tab__input-div {
  display: flex;
  justify-content: space-between;
  background-color: $grey-200;
}

.comments-tab__text-field {
  margin: 34px 0 34px 26px;

  textarea {
    font-size: 16px;
    margin-top: 12px;
  }

  p {
    font-size: 12px;
  }

  fieldset {
    border: none;
  }
}

.comments-tab__text-field-root {
  height: 48px;

  > div {
    overflow-y: hidden;
  }
}

.comments-tab__internal-input::placeholder {
  color: $text-secondary;
}

.comments-tab__right-padding {
  padding: 30px;
}

.comments-tab__right-actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    height: 40px;
    background-color: $grey-700;
  }

  svg {
    padding: 16px;
    color: $grey-700;
  }
}