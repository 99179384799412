.w2-transmittal-status {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    min-height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__text-container {
    display: flex;
    align-items: center;
  }

  &__name {
    font-weight: 600;
  }
}