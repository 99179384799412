@import 'theme/_s1v10colors.scss';

.collapsible-list-fields {
  &__header {
    border-bottom: 1px solid $divider !important;
    min-height: 50px;
    padding: 5px 0;
  }

  &__icon {
    justify-content: right;
    padding-right: 10px;
  }

  &__item {
    width: 100%;
  }

  &__item .tirTextField__container-30 {
    flex-basis: auto !important;
    width: calc(30% - 39px);
  }
}