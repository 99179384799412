@import 'theme/_s1v10colors.scss';

.client-info-container {
  display: flex;
  padding-bottom: 12px;
  order: 1; /* Ensure it stays after the navigation links */
  margin-bottom: 10px; /* Adjust spacing if needed */
}

.client-information {
  display: flex;
  flex-direction: column;
  gap: 3px;
  text-align: left;
  max-height: 40px;
  z-index: 1301;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 20px;

  &__text {
    color: $text-contrast;
    font-size: 12px;
  }

  .sovosNavigationLinks {
    order: 2;
  }
}
