@import 'theme/_s1v10colors.scss';

.summary-of-qdd-row {
  &__label-input {
    width: 100%;

    &__label {
      min-width: 100px;
    }

    &__input {
      height: 50px;
      border-right: 1px solid $divider;
      width: 100%;
    }
  }

  &__right-box {
    margin-left: 15px;
    min-width: 18px;
  }

  &__container {
    width: 100%;
  }
}