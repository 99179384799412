@import 'theme/_s1v10colors.scss';

.summary-of-qdd-header {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

&__label {
  padding-left: 15px;
}

&__text{
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

&__columns{
    width: 42.8%;
    display: flex;
    flex-direction: row;

    &__column{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px;
      border-left: 1px solid $divider;

      > p {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
      }
    }
  }
}