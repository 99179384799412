.coded-payments__container {
  padding: 0;
  display: block !important;

  .coded-payments__select-difference-container {
    width: 100%;
  }
  
  .coded-payments__amount-different-fields {
    width: auto !important;
  } 
}
