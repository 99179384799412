.data-info-section__wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.data-info-section__corp-table {
  width: 65%;
  min-width: 450px;
  margin-right: 20px;
}

.data-info-section__ent-table {
  min-width: 900px;
}

.data-info-section__timeline {
  width: 34%;
  min-width: 200px;
}

.data-info-section__notifications {
  margin-top: -20px;
}