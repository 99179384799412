@import 'theme/_s1v10colors.scss';

.verification-status-icon {
  &_container {
    display: flex;

    h6 {
      color: $text-disabled;
    }

    span {
      color: $text-primary;
    }

    svg {
      margin-right: 8px;
    }
  }
}
