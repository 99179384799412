@import 'theme/_s1v10colors.scss';

.entity-fetcher {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 15px;

  &__label {
    display: flex;
    flex: 1;
    margin: 0;
    font-size: 18px;
    font-weight: 400;

    &-padding {
      padding-left: 24px;
    }
  }

  &__button {
    margin-right: 10px !important;
    margin-left: 20px !important;
  }

  &__type-ahead-blur {
    filter: blur(3px);
    cursor: default;
  }

  .react-autosuggest__input {
    font-size: 14px;
    height: 20px;
    padding: 0;
    width: 210px;
    margin-left: 5px;
  }

  .react-autosuggest__suggestion {
    font-size: 14px;
  }

  .react-autosuggest__suggestions-container--open {
    top: 35px;
  }

  .react-autosuggest__suggestions-list {
    max-height: 250px;
    overflow-y: auto;
  }

  &__type-ahead-container {
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $divider;
    font-size: 14px;
  }

  &__suggestion-preview {
    font-size: 14px;
    &_bold {
      font-weight: 500;
    }
  }

  &__searching-box {
    padding: 10px;
  }
}
