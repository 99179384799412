.refreshIndicatorLoading__style {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  align-items: center;
  z-index: 999;
  color: #015FFF;
}
