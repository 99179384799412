@import 'theme/_s1v10colors.scss';

.record-status-information {
  &__name {
    font-weight: 600;
  }

  &__text-container {
    margin-top: 10px;
    min-height: 0 !important;
    align-items: stretch !important;
  }
}
