@import 'theme/_s1v10colors.scss';

.w2-multiple-local-wage {
  &__container {
    padding: 0;
    display: block !important;
  }

  &__row {
    flex: 1;
    border-right: 1px solid $divider;

    .tirTextfieldForm__wrapper {
      min-height: 50px;
      border-bottom: 1px solid $divider;
    }
  }
}
