.button-field-form {
  &__container {
    margin-top: 2px;
    min-height: 0 !important;
  }

  &__padding {
    padding-left: 0px;
    padding-right: 0px;
  }
}