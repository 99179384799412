@import 'theme/_s1v10colors.scss';

.info-card__card {
  width: 30%;
  height: 11vw;
  max-height: 150px;
  min-height: 95px;
  min-width: 100px;
  text-align: center;
  margin-bottom: 5px;
  padding-top: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  flex: 1 1;
  border: 1px solid $divider;

  .info-card__paragraph {
    color: #545E75;
  }

  .info-card__content {
    position: relative;
  }

  .refreshIndicatorLoading__style {
    top: 60px;
    position: static;
  }

  &.active:hover {
    cursor: pointer;
    margin-bottom: 0;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important;
  }
}

@media screen and (min-width: 1200px) {
  .info-card__card h2 {
    font-size: 48px;
    line-height: 56px;
  }
}
