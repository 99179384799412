@import 'theme/_s1v10colors.scss';

.client-migration {

  &__text-field {
    margin-top: 8px;
  }

  &__checkbox-label {
    color: $text-primary;
    margin-left: 8px;
  }
}