@import 'theme/_s1v10colors.scss';

.semi-weekly-amount {
  &__row {
    border-bottom: 1px solid $divider;
    min-height: 50px;
  }

  &__input {
    width: 30%;
  }
}