.status-circle-icon {
  &__container {
    display: flex;
    align-items: center;
  }

  &__circle {
    margin-right: 10px;
    width: 10px;
  }
}