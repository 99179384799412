@import 'theme/_s1v10colors.scss';

.column-selector__buttons-container {
  padding: 0 14px 16px;
  border-bottom: 1px solid $grey-50;
}

.column-selector__buttons-container > div {
  margin-right: 8px;
}

.column-selector__buttons-container > div:last-child {
  margin-right: 0;
}

.column-selector__columns-container hr {
  border: 0;
  border-top: 1px solid $grey-300;
  margin-bottom: 18px;
  position: relative;
  bottom: 0;
}

.column-selector__columns-container {
  position: absolute;
  top: 110px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 10px 45px 10px;
  overflow: auto;
}

.column-selector__label {
  height: 22px;
  font-size: 15px;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.column-selector__label_visible {
  color: 'primary';
}

.column-selector__label_hidden {
  color: $grey-600;
}

.column-selector__column-row {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  white-space: nowrap;
  height: 35px;
  width: 280px;
}

.column-selector__column-row > div:first-child {
  flex-grow: 1;
}

.column-selector__icon-container {
  cursor: pointer;
  padding-top: 3px;

  svg {
    height: 16px;
    width: 16px;
    fill: 'primary';
  }
}
