.tax-year {
  font-size: 35px;
  margin: 22px 0 0 0;
}

.tax-year__fist_2_letters {
  font-weight: 500;
  color: lightgrey;
}

.tax-year__last_2_letters{
  font-weight: 500;
}
